@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
::after,
::before {
  box-sizing: border-box;
}

:root {
  --amazonOrange: #004c94;
  --lightAmazonOrange: #226db6;
  --darkAmazonOrange: #002a72;
  --color-primary-highlight: #446fd8;
  --color-primary: var(--amazonOrange);
  --ion-color-primary: var(--amazonOrange);
  -gradient-blaze: linear-gradient(
    270deg,
    #446fd8 0%,
    #004c94 100%
  );
  -gradient-blaze: linear-gradient(
    270deg,
    var(--color-primary-highlight) 0%,
    var(--amazonOrange) 100%
  );
  --ion-color-primary-tint: var(--color-primary-highlight);
  --form-color: #fff;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.box-background {
  background: url(/static/media/bg_signin.2651d94a.jpg) no-repeat top center;
  background-size: cover;
  height: 100vh;
}

.box-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  top: 50%;
  position: relative;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.row {
  display: flex;
  flex-direction: row;
  margin-left: -15px;
  align-items: center;
}

.footer {
  background: #004c94;
  padding: 40px 0;
}

.footerLogo {
  display: flex;
  margin-left: 120px;
  cursor: pointer;
}

.footerText {
  display: flex;
  justify-content: flex-end;
  margin-right: 120px;
  color: #fff;
  font-size: 11px;
}

.col-12,
.col-xs-6 {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
}

.col-12 {
  flex-basis: 100%;
  width: 100%;
}

h1.title {
  font-size: 35px;
  line-height: 0.85;
  text-transform: uppercase;
  font-weight: 900;
  color: #fff;
  margin-bottom: 20px;
  padding: 0;
}

.description {
  color: #ffffff;
  font-size: 17px;
}

button[type="submit"]:hover {
  opacity: 1;
  background-color: #ffef00;
  color: #313131;
}

button:disabled {
  position: relative;
}

button:disabled::before {
  content: "";
  color: #fff;
  font-family: sans-serif;
  -webkit-animation: spin 0.7s linear infinite;
          animation: spin 0.7s linear infinite;
  position: absolute;
  left: 13px;
  top: 15px;
  border: 0.15em solid #04b155;
  width: 1.4em;
  height: 1.4em;
  border-radius: 50%;
  border-left-color: transparent;
}

.force-align-center {
}

.spinner {
  border: 0.25em solid rgba(255, 255, 255, 0.8);
  border-left-color: transparent;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  -webkit-animation: spin 0.7s linear infinite;
          animation: spin 0.7s linear infinite;
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* MEDIAS */
@media (min-width: 576px) {
  .box-container {
    max-width: 540px;
  }

  .col-sm-6 {
    flex-basis: 60%;
    width: 60%;
  }

  h1.title {
    font-size: 40px;
  }
}

@media (min-width: 768px) {
  .box-container {
    max-width: 720px;
  }

  h1.title {
    font-size: 60px;
  }
}

@media (min-width: 992px) {
  .box-container {
    max-width: 960px;
  }

  h1.title {
    font-size: 90px;
  }
}

@media (min-width: 1200px) {
  .box-container {
    max-width: 1140px;
  }

  h1.title {
    font-size: 130px;
  }
}

.container {
  font-weight: 400;
}

.formContainer {
  text-align: center;
}

.formSection {
  position: relative;
  margin-bottom: 20px;
  background-color: #fff;
  background-color: var(--form-color);
  padding: 35px 40px;
  text-align: left;
  display: inline-block;
  min-width: 460px;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
}

.sectionHeader {
  font-size: 28px;
  color: rgb(0, 76, 148);
  font-weight: 900;
  margin-bottom: 24px;
}

.sectionBody {
  margin-bottom: 30px;
}

.formField {
  margin-bottom: 22px;
}

.inputLabel {
  color: var(--input-color);
  font-size: 14px;
  margin-bottom: 8px;
}

.sectionFooter {
  font-size: 14px;
  color: var(--grey);
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
}

.sectionFooterPrimaryContent {
  margin-left: auto;
}

.sectionFooterSecondaryContent {
  margin-right: auto;
  align-self: center;
}

.button {
  min-width: 153px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none;
  background-image: none;
  color: var(--button-color);
  background-color: var(--button-background-color);
  padding: 14px 0;
  letter-spacing: 1.1px;
  border: none;

  border-radius: 5px;
  text-transform: none;
  font-weight: bold;
}

.input {
  display: block;
  width: 100%;
  font-size: 14px;
  color: var(--input-color);
  background-color: var(--input-background-color);
  background-image: none;
  box-sizing: border-box;
  margin-bottom: 10px;
  border: 1px solid rgb(61, 61, 61);
  border-radius: 5px;
  padding: 11px 16px;
}

#flashDiv {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  padding: 16px;
  background-color: #004c94;
  background-color: var(--amazonOrange);
  color: #ffffff;
  font-size: 14px;
  box-sizing: border-box;
}

.back-to-sign-in {
  min-width: 153px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none;
  background-image: none;
  border: 1px solid var(--button-background-color);
  background-color: #fff;
  color: var(--button-background-color);
  padding: 14px 0;
  letter-spacing: 1.1px;
  border-radius: 5px;
  text-transform: none;
  font-weight: bold;
}

